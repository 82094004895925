import { BigNumber, providers, utils } from 'ethers';
import {
  initLastGenDepositEthereum,
  initLastGenDepositMantle,
  initWETH,
  initWolfLastGeneration,
} from './init';

async function createData(signer: providers.JsonRpcSigner, chainId: number) {
  console.info(`createData()`);
  console.info(`chainId: ${chainId}`);

  if (chainId === 1 || chainId === 11155111) {
    console.info(`network: ${'Ethereum'}`);
    const lastGenDepositEthereum = await initLastGenDepositEthereum(signer);
    const value = utils.parseUnits('1', 'ether');
    const data = lastGenDepositEthereum.interface.encodeFunctionData(
      'deposit',
      []
    );
    const estimateGas: BigNumber =
      await lastGenDepositEthereum.estimateGas.deposit({ value: value });

    return {
      data: data,
      to: lastGenDepositEthereum.address,
      value: value,
      gasPrice: await signer?.provider?.getGasPrice(),
      gasLimit: estimateGas,
    };
  } else {
    console.info(`network: ${'Mantle'}`);
    const lastGenDepositMantle = await initLastGenDepositMantle(signer);
    const value = utils.parseUnits('1', 'ether');
    const data = lastGenDepositMantle.interface.encodeFunctionData('deposit', [
      value,
    ]);
    const estimateGas: BigNumber = await lastGenDepositMantle
      .connect(signer)
      .estimateGas.deposit(value);

    return {
      data: data,
      to: lastGenDepositMantle.address,
      gasPrice: await signer?.provider?.getGasPrice(),
      gasLimit: estimateGas,
    };
  }
}

export async function depositTxRequest(
  signer: providers.JsonRpcSigner,
  chainId: number
) {
  console.info(`depositTxRequest()`);

  return await createData(signer, chainId);
}

export async function approveSendTransaction(signer: providers.JsonRpcSigner) {
  const weth = await initWETH(signer);
  const lastGenDeposit = await initLastGenDepositMantle(signer);
  const value = utils.parseUnits('1', 'ether');

  const estimateGas: BigNumber = await weth.estimateGas.approve(
    lastGenDeposit.address,
    value
  );

  const tx = await weth.approve(lastGenDeposit.address, value, {
    gasPrice: await signer?.provider?.getGasPrice(),
    gasLimit: estimateGas,
  });

  await tx.wait(1);
  return tx;
}

export async function checkLimit(signer: providers.JsonRpcSigner) {
  const erc721 = await initWolfLastGeneration(signer);
  const mintLimit = (await erc721.mintLimit()).toNumber();
  const totalSupply = (await erc721.totalSupply()).toNumber();

  return mintLimit === totalSupply;
}

export async function totalSupply(signer: providers.JsonRpcSigner) {
  const erc721 = await initWolfLastGeneration(signer);
  return (await erc721.totalSupply()).toNumber();
}
