import { providers } from 'ethers';
import { initWForwarder, initWolfLastGeneration } from './init';
import { WForwarder_gas } from './constant';
import { allow_list } from './allowList';

export type Message = {
  from: string;
  to: string;
  value: number;
  gas: number;
  nonce: string;
  data: string;
};

const ForwardRequestType = [
  { name: 'from', type: 'address' },
  { name: 'to', type: 'address' },
  { name: 'value', type: 'uint256' },
  { name: 'gas', type: 'uint256' },
  { name: 'nonce', type: 'uint256' },
  { name: 'data', type: 'bytes' },
];

function createTypedDataV4(
  chainId: number,
  ForwarderAddress: string,
  message: Message
) {
  const TypedData = {
    primaryType: 'ForwardRequest' as const,
    types: {
      ForwardRequest: ForwardRequestType,
    },
    domain: {
      name: 'MinimalForwarder',
      version: '0.0.1',
      chainId,
      verifyingContract: ForwarderAddress,
    },
    message,
  };
  return TypedData;
}

export async function eip721signature(
  signer: providers.JsonRpcSigner,
  chainId: number,
  address: string
) {
  console.info(`eip721signature()`);

  const forwarder = await initWForwarder(signer);
  const erc721 = await initWolfLastGeneration(signer);

  const nonce = await forwarder.getNonce(address);
  console.info(nonce.toString());

  const data = erc721
    .connect(signer)
    .interface.encodeFunctionData('metaMint', [address]);

  const message: Message = {
    from: address,
    to: erc721.address,
    value: 0,
    gas: WForwarder_gas,
    nonce: nonce.toString(),
    data: data,
  };

  console.info(message);

  const typedData = createTypedDataV4(chainId, forwarder.address, message);

  const signature = await signer._signTypedData(
    typedData.domain,
    typedData.types,
    typedData.message
  );
  console.info('signature = ', signature);

  return { message: message, signature: signature };
}

export async function isWhiteList(
  signer: providers.JsonRpcSigner,
  address: string
): Promise<boolean> {
  console.info(`isWhiteList()`);
  console.info(`${address}`);

  const forwarder = await initWForwarder(signer);
  return await forwarder.isWhiteList(address);
}

export async function isWhiteListWait(address: string): Promise<boolean> {
  console.info(`isWhiteList()`);
  console.info(`${address}`);
  return allow_list.includes(address.toLowerCase());
}
