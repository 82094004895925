import { Contract, providers } from 'ethers';
import {
  LastGenDepositMantle_address,
  WETH_abi,
  Weth_address,
  WForwarder_abi,
  WForwarder_address,
  WolfLastGenDeposit_abi,
  WolfLastGenDepositEthereum_address,
  WolfLastGenDepositWETH_abi,
  WolfLastGeneration_abi,
  WolfLastGeneration_address,
} from './constant';

export async function initLastGenDepositEthereum(
  signer: providers.JsonRpcSigner
) {
  return await new Contract(
    WolfLastGenDepositEthereum_address,
    WolfLastGenDeposit_abi,
    signer
  );
}

export async function initLastGenDepositMantle(
  signer: providers.JsonRpcSigner
) {
  return await new Contract(
    LastGenDepositMantle_address,
    WolfLastGenDepositWETH_abi,
    signer
  );
}

export async function initWETH(signer: providers.JsonRpcSigner) {
  return await new Contract(Weth_address, WETH_abi, signer);
}

export async function initWForwarder(signer: providers.JsonRpcSigner) {
  return await new Contract(WForwarder_address, WForwarder_abi, signer);
}

export async function initWolfLastGeneration(signer: providers.JsonRpcSigner) {
  return await new Contract(
    WolfLastGeneration_address,
    WolfLastGeneration_abi,
    signer
  );
}
