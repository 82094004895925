import Top from 'pages/Top';
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  connectorsForWallets,
  RainbowKitProvider,
  darkTheme,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mantle } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import {
  injectedWallet,
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  safeWallet,
  metaMaskWallet,
  trustWallet,
  uniswapWallet,
  zerionWallet,
  ledgerWallet,
} from '@rainbow-me/rainbowkit/wallets';

const projectId = 'a3b73d2039c3858bc39e1f97a15a5f08';

function App() {
  const defaultChains = [
    // {
    //   ...mantleTestnet,

    //   id: 5003,
    //   name: 'Mantle Testnet',
    //   network: 'mantle',
    //   nativeCurrency: {
    //     decimals: 18,
    //     name: 'MNT',
    //     symbol: 'MNT',
    //   },
    //   rpcUrls: {
    //     default: { http: ['https://rpc.sepolia.mantle.xyz'] },
    //     public: { http: ['https://rpc.sepolia.mantle.xyz'] },
    //   },
    //   blockExplorers: {
    //     etherscan: {
    //       name: 'Mantle Testnet Explorer',
    //       url: 'https://explorer.testnet.mantle.xyz',
    //     },
    //     default: {
    //       name: 'Mantle Testnet Explorer',
    //       url: 'https://explorer.testnet.mantle.xyz',
    //     },
    //   },
    //   testnet: true,
    // },
    {
      ...mantle,
      iconUrl:
        'https://pbs.twimg.com/profile_images/1597775748580134914/bLhE1aY1_400x400.jpg',
    },
  ];

  const { chains, publicClient } = configureChains(defaultChains, [
    publicProvider(),
  ]);

  const connectors = connectorsForWallets([
    {
      groupName: 'Recommended',
      wallets: [
        injectedWallet({ chains }),
        metaMaskWallet({ projectId, chains }),
        // walletConnectWallet({ projectId, chains }),
        coinbaseWallet({ chains, appName: 'WLF Premint' }),
        safeWallet({
          chains: chains,
        }),
        trustWallet({ projectId, chains }),
        uniswapWallet({ projectId, chains }),
        rainbowWallet({ projectId, chains }),
        zerionWallet({ projectId, chains }),
        ledgerWallet({ projectId, chains }),
      ],
    },
  ]);

  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors,
    publicClient,
  });

  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider
        chains={chains}
        // locale=""
        modalSize="wide"
        theme={darkTheme({
          accentColor: '#7b3fe4',
          accentColorForeground: 'white',
          borderRadius: 'medium',
          fontStack: 'rounded',
          overlayBlur: 'large',
        })}
      >
        <Top />
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
