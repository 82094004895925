import { useState, useEffect } from 'react';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useChainId } from 'wagmi';
import { useConnectModal } from '@rainbow-me/rainbowkit';
import { useEthersSigner } from './../lib/provider';
import { eip721signature, isWhiteList } from './../lib/forwarder';
import { checkLimit, totalSupply } from './../lib/contract';
import { isHolder } from './../lib/erc721';
import { relay } from './../lib/server';
import { generateErrorMessage } from './../lib/errors';
import {
  discord_url,
  mantle_tx_explorer_url,
  mantle_tokens_nfts_explorer_url,
  mantle_explorer_tokens_nfts,
} from './../lib/constant';

const Top = () => {
  const [isDeposit, setIsDeposit] = useState(false);
  const { isConnected, address } = useAccount();
  const chainId = useChainId();

  const { openConnectModal } = useConnectModal();
  const signer = useEthersSigner();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setSuccess] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [total, setTotal] = useState(499);
  const [isAllowList, setAllowList] = useState(false);
  const [isAllowListWait, setAllowListWait] = useState(false);
  const [isAlreadyNftHolder, setAlreadyNftHolder] = useState(false);
  const [txHash, setTxHash] = useState('');

  useEffect(() => {
    const fetchAllowList = async () => {
      try {
        setLoading(true);
        // const allowListWait = await isWhiteListWait(address);
        setTotal(await totalSupply(signer, address));
        const allowList = await isWhiteList(signer, address);
        const alreadyHodler = await isHolder(signer, address);
        // setAllowListWait(allowListWait);
        setAllowList(allowList);
        setAlreadyNftHolder(alreadyHodler);
      } catch (error) {
        console.error('Failed to fetch: ', error);
        // setErrorMessage(generateErrorMessage(JSON.stringify(error)));
      } finally {
        setLoading(false);
      }
    };

    if (!isConnected) {
      // openConnectModal();
    } else {
      fetchAllowList();
    }
  }, [signer, isConnected, openConnectModal, address]);

  async function handleMetaTx() {
    if (!isConnected) {
      openConnectModal();
    } else {
      setErrorMessage('');
      setSuccess(false);
      setLoading(true);

      try {
        const { message, signature } = await eip721signature(
          signer,
          chainId,
          address
        );
        const isLimited = await checkLimit(signer);

        if (isLimited) {
          throw new Error('Invalid mintLimit');
        }
        const response = await relay(address, message, signature);

        if (response && response.txHash) {
          setIsDeposit(true);
          setSuccess(true);
          setTxHash(response.txHash);
        }
      } catch (error) {
        console.info(error);
        setErrorMessage(generateErrorMessage(JSON.stringify(error)));
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <div className="l-wrapper">
      <main>
        <div className="p-fv">
          <div className="p-fv__bg">
            <picture>
              <source srcSet="/images/img_sp.jpg" media="(max-width: 768px)" />
              <img src="/images/img_pc.jpg" alt="" decoding="async" />
            </picture>
          </div>

          {isConnected && (
            <div className="p-fv__wallet__button">
              {/* <ConnectButton className="" /> */}
            </div>
          )}

          <div className="p-fv__content">
            <div className="p-fv__content-inner">
              <h1 className="p-fv__ttl">
                <img
                  src="/images/img_ttl.svg"
                  alt="WLF LAST GENERATION"
                  decoding="async"
                />
              </h1>

              <p className="p-fv__txt">PRE-MINT YOUR NFTs</p>

              {!isConnected && (
                <div className="p-fv__btn wallet">
                  {/* <ConnectButton className="p-fv__btn-link" /> */}
                </div>
              )}
              {true && (
                <>
                  {true ? (
                    // totalが499の場合の新しい条件
                    <p className="p-fv__msg_black">
                      {`Missed out on our minting phase?`}
                      <br />
                      {`No worries!`}
                      <br />
                      {`You still have a chance to grab yours from the secondary market.`}
                      <br />
                      {`Head over to our Discord for further info and get in on the action!`}
                      <br />
                    </p>
                  ) : (
                    <>
                      {!isDeposit ? (
                        <>
                          {isAllowList ? (
                            <>
                              {isAlreadyNftHolder ? (
                                <>
                                  {errorMessage ? (
                                    <p className="p-fv__msg">{errorMessage}</p>
                                  ) : (
                                    <p className="p-fv__msg_black">
                                      {`You are already a WLF LAST GENERATION NFT holder.`}
                                      <br />
                                      <img
                                        src="/images/LastGen.png"
                                        alt=""
                                        className="p-fv__img"
                                      />
                                      <a
                                        href={`${mantle_tokens_nfts_explorer_url}${address}${mantle_explorer_tokens_nfts}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        List of NFTs you have
                                      </a>
                                    </p>
                                  )}
                                </>
                              ) : (
                                <>
                                  {errorMessage ? (
                                    <p className="p-fv__msg">{errorMessage}</p>
                                  ) : (
                                    <div className="p-fv__btn mint">
                                      <button
                                        className={`p-fv__btn-link ${isLoading ? 'blinking' : ''}`}
                                        onClick={handleMetaTx}
                                        disabled={isLoading}
                                      >
                                        {isLoading ? 'Loading...' : 'PRE-MINT'}
                                      </button>
                                    </div>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {isAllowListWait ? (
                                <p className="p-fv__msg_black">
                                  {`Mint schedule`}
                                  <br />
                                  {`April 14th, 2024`}
                                  <br />
                                  {`3:00-5:00AM UTC Guaranteed Slots`}
                                  <br />
                                  {`5:00AM- UTC First-Come-First-Served Slots`}
                                  <br />
                                  <a
                                    href={discord_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Discord {'>'} {`${discord_url}`}
                                  </a>
                                </p>
                              ) : (
                                <p className="p-fv__msg_black">
                                  {`Unfortunately, You missed it.`}
                                  <br />
                                  {`Join the Discord Community so you don't miss the next chance!`}
                                  <br />
                                  <a
                                    href={discord_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Discord {'>'} {`${discord_url}`}
                                  </a>
                                </p>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {isSuccess && (
                            <p className="p-fv__msg_black">
                              {`PRE-MINT SUCCESSFUL!`}
                              <br />
                              <img
                                src="/images/LastGen.png"
                                alt=""
                                className="p-fv__img"
                              />
                              {txHash && (
                                <a
                                  href={`${mantle_tx_explorer_url}${txHash}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {`Here are your transaction details`}
                                </a>
                              )}
                            </p>
                          )}
                          {errorMessage && (
                            <p className="p-fv__msg">{errorMessage}</p>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          <p className="p-fv__logo">
            <a
              href="https://wlfproject.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/icon_wlf.svg" alt="" />
            </a>
          </p>
        </div>
      </main>
    </div>
  );
};

export default Top;
