import { providers } from 'ethers';
import { initWolfLastGeneration } from './init';

export async function isHolder(
  signer: providers.JsonRpcSigner,
  address: string
): Promise<boolean> {
  console.info(`isHolder()`);
  console.info(`${address}`);

  const erc721 = await initWolfLastGeneration(signer);
  return (await erc721.balanceOf(address)).toNumber() > 0;
}
